import React, {Component} from 'react';
import { Badge, Row, Col, Card, Button,CardHeader, CardFooter, CardBody, CardTitle, CardText, ListGroup, ListGroupItem, Table  } from 'reactstrap';
import ProductListGroupItem from '../components/ProductListGroupItem'

class BinListGroupProductRow extends Component {
    constructor(props) {
        super(props);
    }

    getBadgeByLocationType(locationType){
        let location = this.props.product.getLocationForType(locationType);
        if(!location) return "";
        if(location.getCode() === this.props.code)
            return <Badge color="secondary" className="horizontal-margin location-type-badge" pill>{locationType}</Badge>;
        else return "";
    }

    render() {
        return  <tr>
                <ProductListGroupItem product={this.props.product} code={this.props.code}/>
        </tr>
    }
}

export default BinListGroupProductRow;
