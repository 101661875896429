import React, {Component} from 'react';
import { Badge, Row, Col, Card, Button,CardHeader, Label, CardBody, ListGroup, CardText } from 'reactstrap';
import warehouseClient from '../components/WarehouseClientSingleton'
import ProductListGroupItem from "../components/ProductListGroupItem";

//TODO "location" has another meaning. DON'T USE IT.

class ProductDisplay extends Component {
    constructor(props) {
        super(props);
    }

    static getBadgeColor(warehouseLocation){
        if(!warehouseLocation) return "dark";
        if(warehouseLocation.binCode) return "info";
        return "dark";
    }

    static getBinLocationBadge(warehouseLocation){
        if(!warehouseLocation)
            return "";
        if(!warehouseLocation.binCode)
            return "";
        if(warehouseLocation.binLocation)
            return <Badge color="dark" className="mr-2">{warehouseLocation.binLocation.code}</Badge>;
        else
            return <Badge color="dark" className="mr-2">NOLOC</Badge>;
    }

    static renderLocationCard({warehouseLocation, header}){
        const updatedDate = warehouseLocation && warehouseLocation.audit && warehouseLocation.audit.updated ? new Date(warehouseLocation.audit.updated.date) : null;
        return <Card>
            <CardHeader>{header}</CardHeader>
            <CardBody>
                <div>
                    <h2>
                        {ProductDisplay.getBinLocationBadge(warehouseLocation)}
                        <Badge color={ProductDisplay.getBadgeColor(warehouseLocation)}>{warehouseLocation ? warehouseLocation.getCode() : "NOLOC"}</Badge>
                    </h2>
                    <h2>
                        {warehouseLocation ? warehouseLocation.name : ""}
                    </h2>
                    {updatedDate ? <h6 className="pl-4">Updated {updatedDate.toLocaleDateString()}</h6> : ""}
                </div>
            </CardBody>
        </Card>
    }

    render() {
        let primaryLocation = this.props.product.locations.find(warehouseLocation => warehouseLocation.locationType === "PRIMARY");
        let reserveLocation = this.props.product.locations.find(warehouseLocation => warehouseLocation.locationType === "RESERVE");

        let primaryLocationContent = <Col md="6">
            {ProductDisplay.renderLocationCard({warehouseLocation: primaryLocation, header: "Primary Location"})}
        </Col>;

        let reserveLocationContent = <Col md="6">
            {ProductDisplay.renderLocationCard({warehouseLocation: reserveLocation, header: "Reserve Location"})}
        </Col>;

        return <div className="left-margin">
            <h2>Product</h2>
            <div className="x3Left-margin">
                <Row className="align-items-center">
                    <h3 className="">{this.props.product.name}</h3>
                    <h3 className="left-margin"><Badge color="primary" className="float-right m-2">K{this.props.product.productId}</Badge></h3>
                    <h4><Button color="primary" className="left-margin" size="sm" outline={true}
                                href={`https://packing.kjdelectronics.com/packing/${this.props.product.productId}`} target="_blank">
                        Packing</Button></h4>
                </Row>
                <Row>
                    {primaryLocationContent}
                    {reserveLocationContent}
                </Row>
            </div>
        </div>
    }
}

export default ProductDisplay;
