import React, {Component} from 'react';
import { Input } from 'reactstrap';
import warehouseClient from './WarehouseClientSingleton'
import PropTypes from 'prop-types';

class LocationGroupSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: ''
        };
        this.currentSearchTimestamp = null
    }

    handleChange(e) {
        this.setState({
            searchText: e.target.value
        });
        if(this.props.onTextChange)
            this.props.onTextChange(e.target.value);

        this.currentSearchTimestamp = new Date().getTime();
        const scopeSearch = this.currentSearchTimestamp;

        if(e.target.value === ""){
            this.props.onLocationGroupChange(null);
            return;
        }

        this.searchPending = true;
        this.searchFinishCallback = null; //Clear ending pending callback
        let t = this;

        warehouseClient.searchForLocationGroup(e.target.value)
            .then(location => {
                if(scopeSearch === t.currentSearchTimestamp)
                    t.props.onLocationGroupChange(location);
                this.finishSearch();
            })
            .catch(err => {
                if(err.statusCode !== 404){
                    return; //TODO Error message
                }
                if(scopeSearch === t.currentSearchTimestamp)
                    t.props.onLocationGroupChange(null);
                this.finishSearch();
            });

    }

    finishSearch(){
        this.searchPending = false;
        if(this.searchFinishCallback) {
            this.searchFinishCallback();
            this.searchFinishCallback = null;
        }
    }

    handleKeyPress(e){
        if(this.props.onKeyPress)
            this.props.onKeyPress(e);
        if (e.key === "Enter")
            this.onEnter(e);
    }

    onEnter(e){
        if(!this.props.onEnter) return;

        if(this.searchPending) {
            this.searchFinishCallback = () => {
                setTimeout(() => this.props.onEnter(e), 100);
            };
        }
        else
            setTimeout(() => this.props.onEnter(e), 100);
    }

    clearText(){
        this.setState({searchText: ''});
        this.props.onLocationGroupChange(null);
    }

    getText(){
        return this.state.searchText;
    }

    render() {
        return <Input placeholder={!this.props.disabled ? "UA" : ""} bsSize="lg" className={`${this.props.className}`}
                      id={this.props.id}
                      value={this.state.searchText}
                      disabled={this.props.disabled}
                      onChange={e => this.handleChange(e)}
                      onKeyPress={e => this.handleKeyPress(e)}
        />
    }
}

LocationGroupSearch.propTypes = {
    onLocationGroupChange: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func,
    onEnter: PropTypes.func,
    onTextChange: PropTypes.func
};

export default LocationGroupSearch;
