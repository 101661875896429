import React, {Component} from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import ProductDisplay from '../displays/ProductDisplay'
import BinDisplay from '../displays/BinDisplay'
import LocationDisplay from "../displays/LocationDisplay";
import LocationGroupDisplay from "../displays/LocationGroupDisplay";
import GenericSearch from "../components/GenericSearch";

class ReadOnly extends Component {
    constructor(props) {
        super(props);

        this.state = {result: null};
        this.searchRef = React.createRef();
    }

    onResultChange(result){
        this.setState({
            result: result
        });
    }

    handleKeyPress(e){
        if (e.key === "Enter") {
            this.searchRef.current.clearText();
        }
    }

    render() {
        let content = "";
        if(this.state.result) {
            if (this.state.result.type === "product")
                content = <ProductDisplay product={this.state.result.data}/>;
            else if (this.state.result.type === "location")
                content = <LocationDisplay location={this.state.result.data}/>;
            else if (this.state.result.type === "group")
                content = <LocationGroupDisplay group={this.state.result.data} />;
            else if (this.state.result.type === "bin")
                content = <BinDisplay bin={this.state.result.data}/>;
        }

        return    <div>
            <FormGroup>
                <Label for="search"><h2>Search</h2></Label>
                <GenericSearch ref={this.searchRef} onResultChange={result => this.onResultChange(result)}
                               onKeyPress={e => this.handleKeyPress(e)}/>
            </FormGroup>
            <div>
                {content}
            </div>
        </div>
    }
}

export default ReadOnly;
