import { Audit } from './audit/Audit';

class ProductLocation{
    constructor({locationType, locationCode, binCode, name, audit, binLocation}){
        if(locationCode && binCode)
            throw new Error("Product Location can not have both a location and bin set");

        //Product location without locationCode is binCode has no location set for the specified locationType
        if(locationCode)
            this.locationCode = locationCode;
        else if(binCode)
            this.binCode = binCode;

        this.locationType = locationType;
        if(audit == null)
            throw new Error(`Bin requires an audit object`);
        this.audit = new Audit(audit);
        this.binLocation = binLocation;

        if(this.locationCode == null && this.binCode == null)
            this.name = null;
        else {
            this.name = name;
            this._originalName = name;
        }
    }

    getCode(){
        if(this.locationCode) return this.locationCode;
        if(this.binCode) return this.binCode;
        return "NOLOC";
    }

    getName(){
        if(this.name == null || this.getCode() === "NOLOC") return "No Location";
        else return this.name;
    }

    setLocationOfBin(location){
        let Location = require('./Location').Location; //Bizzar unknown issue where this can't be included at top of file
        if(!this.binCode) //Can only be called when product is in a bin
            throw new Error("This method sets the Location of the bin. If this product is not in a bin (ie does not have a binCode) then this method cannot be called");
        if(location && !(location instanceof Location))
            throw new Error(`location must be instance of Location. Got ${location}. JSON: ${JSON.stringify(location)}`);
        this.binLocation = location;
        this._updateNameWithBinLocation();
    }

    _updateNameWithBinLocation(){
        if(!this.binLocation)
            return;
        this.name = `${this.binLocation.name}, ${this._originalName}`;
    }

    getForDb(){
        let {["binLocation"]:omit, ...res} = this;
        delete this._originalName;
        return res;
    }

    toJSON(){
        let {["_originalName"]:omit, ...res} = this;
        return res;
    }
}

export { ProductLocation };