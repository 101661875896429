import { Row, Col, Label } from 'reactstrap';
import React from "react";
import PropTypes from 'prop-types';
import {getProductLocationName} from "../presentation/ProductPresenter";

const ProductNameLocationRow = props => {
    return (
        <Row className>
            <Col md={8}>
                <Label className="medium"><b>Selected Product</b>: {(props.product && (`K${props.product.productId} - ` + props.product.name)) || "None"}</Label>
            </Col>
            <Col>
                <Label className="medium"><b>Location</b>: {getProductLocationName(props)}</Label>
            </Col>
        </Row>
    )
};

ProductNameLocationRow.propTypes = {
    product: PropTypes.object.isRequired,
    locationType: PropTypes.string.isRequired,
};

export default ProductNameLocationRow;
