import React, {Component} from 'react';
import { FormGroup, Label, Row, Col, Card, CardBody, CardHeader, Alert, ListGroupItem, Badge} from 'reactstrap';
import ProductSearch from "./ProductSearch";
import BinSearch from "./BinSearch";
import LocationSearch from "./LocationSearch";
import BatchActionsTable from "./BatchActionsTable";
import {getBinLocationName, getBinProductsList} from "../presentation/BinPresenter";
import {getProductLocationName} from "../presentation/ProductPresenter";
import Toggle from "../lib/bootstrap4-toggle";
const Action = require('../vendor/obj/Action').Action;
const Batch = require('../vendor/obj/Batch').Batch;

class BatchItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            batch: [],
            product: null,
            bin: null,
            clearBinOfAllProducts: true,
            location: null,
            errorMessage: null,
            errors: []
        };

        this.productSearchRef = React.createRef();
        this.binSearchRef = React.createRef();
        this.locationSearchRef = React.createRef();

        this.removeActionItem = this.removeActionItem.bind(this);
    }

    onProductChange(product){
        this.setState({product: product});
    }

    onBinChange(bin){
        this.setState({bin: bin});
    }

    onLocationChange(location){
        this.setState({location: location});
    }

    onClearBinOfAllProductsToggle() {
        console.log("TOGGLE");
        this.setState({ clearBinOfAllProducts: !this.state.clearBinOfAllProducts });
    }

    handleProductEnter(){
        document.getElementById(`${this.props.searchId}Bin`).focus();
    }

    handleBinEnter(){
        document.getElementById(`${this.props.searchId}Location`).focus();
    }

    getErrorAlert(){
        if(this.state.errors.length === 0)
            return;
        return <Alert color="danger">
            {this.getErrorPoints()}
        </Alert>
    }

    getErrorPoints(){
        return this.state.errors.map(error => {
            if (error)
                return <li>{error}</li>;
        });
    }

    handleLocationEnter(){
        let actionItem = this.addActionItem();
        if(!actionItem) return;

        this.locationSearchRef.current.clearText();
        this.binSearchRef.current.clearText();
        this.productSearchRef.current.clearText();
        document.getElementById(`${this.props.searchId}Product`).focus();
    }

    applySearchValidationErrors(errors){
        if(this.productSearchRef.current.getText().trim() !== "" && !this.state.product)
            errors.push("Product not found");
        if(this.binSearchRef.current.getText().trim() !== "" && !this.state.bin)
            errors.push("Bin not found");
        if(this.locationSearchRef.current.getText().trim() !== "" && !this.state.location)
            errors.push("Location not found");
        return errors;
    }

    addActionItem(){
        let action = new Action({
            location: this.state.location,
            bin: this.state.bin,
            product: this.state.product,
            clearBinOfAllProducts: this.state.bin && this.state.clearBinOfAllProducts
        });

        let currentBatch = new Batch({actions: this.state.batch});
        let errors = currentBatch.getErrorsForAddingActionToBatch(action);

        this.applySearchValidationErrors(errors);

        if(errors.length !== 0){
            this.setState({errors: errors});
            BatchItems.playErrorSound();
            return;
        }

        let newBatch = append(action, this.state.batch);
        this.setState({
            batch: newBatch,
            errors: []
        });
        if(this.props.onBatchChange)
            this.props.onBatchChange(newBatch);
        return action;
    }

    removeActionItem(index){
        let newBatch =this.state.batch.slice();
        newBatch.splice(index, 1);
        this.setState({batch: newBatch});
    }

    resetBatch(){
        this.setState({
            batch: [],
            errors: []
        });
        this.productSearchRef.current.clearText();
        document.getElementById(`${this.props.searchId}Product`).focus();
    }

    static playErrorSound(){
        let audio = new Audio('http://soundbible.com/mp3/Computer%20Error%20Alert-SoundBible.com-783113881.mp3');
        audio.type = 'audio/mp3';
        return audio.play(); //Promise
    }

    render() {
        return <Card>
            <CardHeader>2) Add Batch Items</CardHeader>
            <CardBody>
                <FormGroup className="input-within-card">
                    <Row>
                        <Col>
                            <ProductSearch id={`${this.props.searchId}Product`} className="top-margin" ref={this.productSearchRef}
                                           onEnter={e => this.handleProductEnter()}
                                           onProductChange={product => this.onProductChange(product)}/>
                            <Label className="medium">Product: {(this.state.product && (`K${this.state.product.productId} - ` + this.state.product.name)) || "None"}</Label><br/>
                            <Label className="medium">Location: {getProductLocationName({product: this.state.product, locationType: this.props.locationType})}</Label><br/>
                        </Col>
                        <Col md={3}>
                           <BinSearch id={`${this.props.searchId}Bin`} className="top-margin" ref={this.binSearchRef}
                                       onEnter={e => this.handleBinEnter()}
                                       onBinChange={bin => this.onBinChange(bin)}/>
                            <Label className="medium">Bin: {(this.state.bin && this.state.bin.name) || "None"}</Label><br/>
                            <Label className="medium">Location: {getBinLocationName({bin: this.state.bin})}</Label><br/>
                            <Label className="medium">Products: {getBinProductsList({bin: this.state.bin})}</Label><br/>
                            <Toggle
                                onToggle={(value) => this.onClearBinOfAllProductsToggle(value)}
                                label="Clear bin of all products?"
                                default={true}
                                id="batch-toggle-1"
                                tabindex={-1}
                            />
                        </Col>
                        <Col md={3}>
                            <LocationSearch id={`${this.props.searchId}Location`} className="top-margin" ref={this.locationSearchRef}
                                            onEnter={e => this.handleLocationEnter()}
                                            onLocationChange={location => this.onLocationChange(location)}/>
                            <Label className="medium">Location: {(this.state.location && this.state.location.name) || "None"}</Label><br/>
                        </Col>
                    </Row>
                    <Row><Col>{this.getErrorAlert()}</Col></Row>
                    <Label className="medium"><b>Batch Summary</b>: </Label>
                    <BatchActionsTable actions={this.state.batch} removeCallback={this.removeActionItem}/>
                </FormGroup>
            </CardBody>
        </Card>
    }
}

function append(value, array) {
    let newArray = array.slice();
    newArray.push(value);
    return newArray;
}

export default BatchItems;
