import React, {Component} from 'react';
import { Badge, Row, Col, Card, Button,CardHeader, CardFooter, CardBody, CardTitle, CardText, ListGroup, ListGroupItem, Table  } from 'reactstrap';
import ProductListGroupItem from '../components/ProductListGroupItem'
import BinListGroupProductRow from "./BinListGroupProductRow";

class BinListGroupItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return  <ListGroupItem>
            <h5><Badge color="info" className="float-left right-margin">{this.props.bin.code}</Badge> {this.props.bin.name}</h5>
            <Table size="sm" className="float-left table-borderless">
                <tbody>
                {
                    this.props.bin.products.map(product =>  <BinListGroupProductRow product={product} code={this.props.bin.code}/>)
                }
                </tbody>
            </Table>
        </ListGroupItem>
    }
}

export default BinListGroupItem;
