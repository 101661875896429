export default {
    products: {
        get: {
            search: 'products?search={query}',
            all: 'products'
        },
        patch: 'products'
    },
    bins: {
        get:{
            byCode: 'bins/{code}'
        },
        delete:{
            clear: 'bins/{code}'
        }
    },
    locations: {
        get:{
            byCode: 'locations/{code}'
        },
        delete:{
            clear: 'locations/{code}'
        },
        groups:{
            get:{
                byCode: 'locations/groups/{code}'
            },
            delete:{
                clear: 'locations/groups/{code}'
            }
        },
    },
    search:{
        get:{
            search: 'ui?search={query}'
        }
    },
    assignment:{
        post:{
            assignment: 'assignment'
        }
    },
    batches:{
        post: 'batches'
    },
    events:{
        post: 'events'
    }
};