import React, {Component} from 'react';
import { Col, Button, Form, FormGroup, Label, Input, Card, CardBody, CardHeader, Row, Alert, ListGroupItem, Badge } from 'reactstrap';
import BatchItems from "../components/BatchItems";
import Cookies from 'universal-cookie';

//TODO Note the singleton import was not working here for some reason. Possible based on when this is loaded?
const WarehouseClient = require('../vendor/client/WarehouseClinet').WarehouseClient;
const cookies = new Cookies();
const warehouseClient = new WarehouseClient({sessionKey: cookies.get('kjd_session')});

class BulkAssignment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            batch: [],
            type: "primary",
            successMessage: null,
            resultSuccess: null
        };

        this.batchRef = React.createRef();
    }

    onBatchChange(batch){
        this.setState({
            batch: batch
        });
    }

    onBinChange(bin){
        this.setState({bin: bin});
    }

    onLocationChange(location){
        this.setState({location: location});
    }

    onTypeChange(e){
        this.setState({
            type: e.target.value
        });
    }

    getMessageAlert(){
        if(!this.state.resultMessage) return;
        return <Alert color={this.state.resultSuccess ? "success" : "danger"} isOpen={this.state.resultMessageVisible} toggle={e => this.onDismiss()}>
            {this.state.resultMessage}
        </Alert>
    }

    resetBatch(){
        this.batchRef.current.resetBatch();
    }

    submit(){
        let t = this;

        warehouseClient.createBatch({actions: this.state.batch, locationType: this.state.type})
            .then(result => {
                t.setState({
                    resultSuccess: true,
                    resultMessageVisible: true,
                    resultMessage: "Batch saved successfully"
                });
                this.resetBatch();
            }).catch(err => {
                t.setState({
                    resultMessage: `$Error: ${err.error && err.error.message}`,
                    resultMessageVisible: true,
                    resultSuccess: false
                })
        });
    }

    render() {
        return  <div>
            <h2>Batch Mode</h2>
            {this.getMessageAlert()}
            <FormGroup>
                <Label className="large">1) Select Location Type</Label>
                <FormGroup className="left-margin ">
                    <Label className="medium pl-4" check>
                        <Input type="radio"
                               tabIndex="-1"
                               value="primary"
                               checked={this.state.type === "primary"}
                               onChange={e => this.onTypeChange(e)}  />{' '}
                        Primary
                    </Label>
                    <Label className="medium pl-4" check>
                        <Input type="radio"
                               tabIndex="-1"
                               value="reserve"
                               checked={this.state.type === "reserve"}
                               onChange={e => this.onTypeChange(e)}
                        />{' '}
                        Reserve
                    </Label>
                </FormGroup>
            </FormGroup>
            <BatchItems searchId="bulkSearch" locationType={this.state.type} onBatchChange={batch => this.onBatchChange(batch)} ref={this.batchRef}/>
            <FormGroup className="top-margin">
                <Button color="secondary" size="lg" onClick={e => this.resetBatch()}>Reset</Button>
                <Button color="primary" size="lg" className="left-margin" onClick={e => this.submit()} disabled={this.state.batch.length === 0}>Save Batch</Button>
            </FormGroup>
        </div>
    }
}

export default BulkAssignment;