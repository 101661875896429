import {Table, Button } from 'reactstrap';
import React from "react";
import PropTypes from 'prop-types';

function actionToRow({action, index, removeCallback}) {
    return (<tr>
        <th scope="row">{index + 1}</th>
        <td>{action.product && `K${action.product.productId}`}</td>
        <td>{action.bin && action.bin.name}</td>
        <td>{action.clearBinOfAllProducts ? "YES" : ""}</td>
        <td>{action.location && action.location.name}</td>
        <td>{action.toString()}</td>
        <td><Button color="secondary" size="sm" onClick={e => removeCallback(index)}>X</Button></td>
    </tr>)
}

const BatchActionsTable = props => {
    if(!props.actions || props.actions.length === 0)
        return "";

    return (
        <Table>
            <thead>
            <tr>
                <th>#</th>
                <th>KID</th>
                <th>Bin</th>
                <th>Clear Bin?</th>
                <th>Location</th>
                <th>Description</th>
                <th style={{"width": "10%"}}>Remove</th>
            </tr>
            </thead>
            <tbody>
            {
                props.actions.map((action, index) => actionToRow({action: action, index: index, removeCallback: props.removeCallback}))
            }
            </tbody>
        </Table>
    )
};

BatchActionsTable.propTypes = {
    actions: PropTypes.array.required,
    removeCallback: PropTypes.func.required,
};

export default BatchActionsTable;
