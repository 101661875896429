import { Action } from './Action';

class Batch{
    constructor({actions, userId, locationType}){
        this.actions = [];
        actions.forEach(action => this.actions.push(new Action(action)));

        this.userId = userId;
        this.locationType = locationType;
    }

    isValid(){
        return this.getErrors().length === 0;
    }

    getErrors(){
        let errors = [];
        let productSet = new Set();
        let clearBinSet = new Set(); //Set of bins that have CLEAR set. Only 1 clear per bin is valid and only the clear action can include a product for that bin
        let binsAssignedToLocationSet = new Set();

        this.actions.forEach((action, index) => {
            if(!action.isValid())
                errors.push(`Action #${index + 1} is invalid`);

            if(action.product) {
                if (productSet.has(action.product.productId))
                    errors.push(`Product K${action.product.productId} is already in this batch and cannot be added again.`);
                else
                    productSet.add(action.product.productId);

                if(action.bin && clearBinSet.has(action.bin.code))
                    errors.push(`Bin ${action.bin.code} is already included with the Clear Bin option and another product cannot be added to that bin in this batch. Consider clearing the bin manually first.`);
            }

            if(action.clearBinOfAllProducts){
                if(clearBinSet.has(action.bin.code))
                    errors.push(`Bin ${action.bin.code} is already included with the Clear Bin option.`);
                else
                    clearBinSet.add(action.bin.code);

                if(!action.product)
                    errors.push(`Cannot use Clear Bin option without also assigning a product to put into the bin (Otherwise bin will be empty).`);
            }


            if(action.bin && action.location) {
                if(binsAssignedToLocationSet.has(action.bin.code))
                    errors.push(`Bin ${action.bin.code} already has a location in this batch. The bin can be added without the location specified again`);
                else
                    binsAssignedToLocationSet.add(action.bin.code)
            }
        });
        return errors;
    }

    getErrorsForAddingActionToBatch(action){
        let testActions = this.actions.slice(0);
        testActions.push(action);
        const newBatch = new Batch({actions: testActions, userId: this.userId});
        return newBatch.getErrors();
    }

    addAction(action){
        this.actions.push(action);
    }

    getBinToLocationAssignments(){
        return this.actions.filter(action => action.hasBinToLocation);
    }

    getProductToBinAssignments(){
        return this.actions.filter(action => action.hasProductToBin);

    }

    getProductToLocationAssignments(){
        return this.actions.filter(action => action.hasProductToLocation);
    }

    getActionsWithBinClear(){
        return this.actions.filter(action => action.clearBinOfAllProducts);
    }

    toString(){
        let string = `Batch Details: UserId: ${this.userId}, Location Type: ${this.locationType}, Actions Count: ${this.actions.length}`;
        for (let i = 0; i < this.actions.length; i++) {
            const action = this.actions[i];
            string = string + `\r\n  ${i+1}) ${action.toString()}`
        }
        return string;
    }
}

export { Batch };