import { Audit } from './audit/Audit';
import { Product } from './Product';

class Bin{
    constructor({_id, id, name, code, audit, locationCode = "NA", products=null}){
        if(_id) this.id = _id.toString();
        else this.id = id;

        this.name = name;
        this.code = code;
        this.locationCode = locationCode;
        if(audit == null)
            throw new Error(`Bin requires an audit object`);
        this.audit = new Audit(audit);

        if(products && !Array.isArray(products))
            throw new Error("products must be an array if provided");
        if(products){
            let constructedProducts = [];
            products.forEach(p => constructedProducts.push(new Product(p)));
            this.products = constructedProducts;
        }
    }

    setProductsInBin(products){
        this.products = products;
    }

    getForDb(){
        let {["id"]:omit, ...res} = this;
        delete res.products;
        return res;
    }
}

export { Bin };