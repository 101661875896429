class Action {
    constructor({product, bin, location, clearBinOfAllProducts=false}){
        this.product = product;
        this.bin = bin;
        this.location = location;
        this.clearBinOfAllProducts = clearBinOfAllProducts;
    }

    isValid(){
        if(this.clearBinOfAllProducts && (!this.bin || !this.product)) {
            console.error('Cannot clear bin of all products when no bin specified. A product must also be specified otherwise the bin will be empty');
            return false;
        }

        let count = 0;
        if(this.product)
            count += 1;
        if(this.bin)
            count +=1;
        if(this.location)
            count +=1;
        return count >=2;
    }

    toString(){
        let message = "";
        if(this.product)
            message = `K${this.product.productId}`;
        if(message && this.bin)
            message = message + ' -> ';
        if(this.bin) {
            message = message + this.bin.code;
            if(this.clearBinOfAllProducts)
                message = message + "(CLEAR BIN OF OTHER PRODUCTS)";
        }
        if(this.location)
            message = message + ' -> ' + this.location.code;
        return message;
    }

    get hasBinToLocation(){
        return !!this.bin && !!this.location;
    }

    get hasProductToLocation(){
        if(this.hasBinToLocation)
            return false;
        return !!this.product && !!this.location;
    }

    get hasProductToBin(){
        return !!this.product && !!this.bin;
    }


    //Cases:
    //Product, bin, location x
    //Product, Location x
    //Product, Bin x
    //Bin, Location
    verboseString(){
        //Product, Bin, Location and Product Bin
        if(this.product && this.bin && this.location) {
            let message = `Assign K${this.product.productId} to Bin ${this.bin.code}. ${this.clearBinOfAllProducts ? "Clear the bin of other products." : ""}`;
            if(this.location)
                return  message + ` Assign Bin ${this.bin.code} to location ${this.location.code}`;
            else return message;
        }

        if(this.product && this.bin)
            return `Assign K${this.product.productId} to Bin ${this.bin.code}. ${this.clearBinOfAllProducts ? "Clear the bin of other products" : ""}`;

        if(this.product && this.location)
            return `Assign K${this.product.productId} directly to location ${this.location.code}`;

        if(this.bin && this.location)
            return `Assign Bin ${this.bin.code} to location ${this.location.code}`;
    }

}

export { Action };