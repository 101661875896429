import React, {Component} from 'react';
import { Col, Row, Button, FormGroup, Label, Input, Card, CardHeader, CardBody, Alert } from 'reactstrap';
import ProductSearch from "../components/ProductSearch";
import BinSearch from "../components/BinSearch";
import LocationSearch from "../components/LocationSearch";
import ProductNameLocationRow from "../components/ProductNameLocationRow"
import BinNameLocationRow from "../components/BinNameLocationRow"
import Toggle from "../lib/bootstrap4-toggle"
import Cookies from 'universal-cookie';

//TODO Note the singleton import was not working here for some reason. Possible based on when this is loaded?
const WarehouseClient = require('../vendor/client/WarehouseClinet').WarehouseClient;
const cookies = new Cookies();
const warehouseClient = new WarehouseClient({sessionKey: cookies.get('kjd_session')});

class ProductBinLocation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: null,
            bin: null,
            location: null,
            type: "primary",
            clearBinOfOtherProducts: true,
            resultMessage: "",
            resultSuccess: true,
            resultMessageVisible: false,
            messageTimeout: null
        };

        this.productSearchRef = React.createRef();
        this.binSearchRef = React.createRef();
        this.locationSearchRef = React.createRef();
    }

    submitAssignment(){
        let t = this;
        warehouseClient.assignment({
            productId: t.state.product ? t.state.product.productId : null,
            binCode: t.state.bin ? t.state.bin.code : null,
            locationCode:  t.state.location ? t.state.location.code : null,
            locationType: t.state.type,
            clearBinOfAllProducts: t.state.bin && t.state.clearBinOfOtherProducts
        }).then(result => {
            t.setState({
                resultMessage: result.message,
                resultSuccess: true,
                resultMessageVisible: true
            })

        }).catch(err => {
            t.setState({
                resultMessage: `$Error: ${err.error && err.error.message}`,
                resultMessageVisible: true,
                resultSuccess: false
            })
        });
        this.clearSelection();
        document.getElementById("locationAssignmentProductInput").focus();
    }

    clearSelection(){
        this.productSearchRef.current.clearText();
        this.binSearchRef.current.clearText();
        this.locationSearchRef.current.clearText();
    }

    onDismiss() {
        this.setState({ resultMessageVisible: false });
    }

    getMessageAlert(){
        if(!this.state.resultMessage) return;
        return <Alert color={this.state.resultSuccess ? "success" : "danger"} isOpen={this.state.resultMessageVisible} toggle={e => this.onDismiss()}>
            {this.state.resultMessage}
        </Alert>
    }

    // This checks ENTER key (13), then checks if next node is an INPUT
    // Then focuses next input box
    handleKeyPress(e, field) {
        // If enter key is pressed, focus next input field.
        if (e.key === "Enter") {

            if(field === "productInput") {
                document.getElementById("locationAssignmentBinInput").focus();
                e.preventDefault();
            }
            else if(field === "binInput"){
                document.getElementById("locationAssignmentLocationInput").focus();
                e.preventDefault();
            }
        }
    }

    onLocationEnter(){
        this.submitAssignment();
    }

    onTypeChange(e){
        this.setState({
            type: e.target.value
        });
    }

    onClearBinOfAllProductsToggle(value) {
        this.setState({ clearBinOfOtherProducts: value });
    }

    onProductChange(product){
        this.setState({
            product: product
        });
    }

    onBinChange(bin){
        this.setState({
            bin: bin
        });
    }

    onLocationChange(location){
        this.setState({
            location: location
        });
    }

    render() {
        return <div>
            <h2>Location Assignment</h2>
            {this.getMessageAlert()}
            <FormGroup>
                <Card>
                    <CardHeader>1) Select Product and/or Bin
                        <span className="small"> <br/><b>Product Only</b>: Assigns product to location. <b>Bin Only</b>: Assigns bin to location.
                        <b> Product and Bin</b>: Assigns product to bin and bin to location.</span>
                    </CardHeader>
                    <CardBody>
                        <Label for="exampleEmail" className="large">Select Product</Label>
                        <Row>
                            <Col sm="6" md="8">
                                <ProductSearch ref={this.productSearchRef} className="input-within-card"
                                               id="locationAssignmentProductInput"
                                    onKeyPress={e => this.handleKeyPress(e, "productInput")}
                                               onProductChange={product => this.onProductChange(product)}/>
                            </Col>
                            <Col sm="6" md="4">
                                <Label className="large">Location Type</Label>
                                <Label className="medium pl-4" check>
                                    <Input type="radio"
                                           tabIndex="-1"
                                           value="primary"
                                           checked={this.state.type === "primary"}
                                           onChange={e => this.onTypeChange(e)}  />{' '}
                                    Primary
                                </Label>
                                <Label className="medium pl-4" check>
                                    <Input type="radio"
                                           tabIndex="-1"
                                           value="reserve"
                                           checked={this.state.type === "reserve"}
                                           onChange={e => this.onTypeChange(e)}
                                    />{' '}
                                    Reserve
                                </Label>
                            </Col>
                        </Row>
                        <ProductNameLocationRow product={this.state.product} locationType={this.state.type}/>
                        <FormGroup>
                            <Label for="exampleEmail" className="large">Select Bin</Label>
                            <Row>
                                <Col sm="6" md="8">
                                    <BinSearch placeholder="B-XS-1" bsSize="lg" className="input-within-card" ref={this.binSearchRef}
                                           id="locationAssignmentBinInput"
                                           onKeyPress={e => this.handleKeyPress(e, "binInput")}
                                           onBinChange={bin => this.onBinChange(bin)}/>
                                </Col>
                                <Col sm="6" md="4">
                                    <Toggle
                                        onToggle={(value) => this.onClearBinOfAllProductsToggle(value)}
                                        label="Clear bin of all products?"
                                        default={true}
                                        id="assignment-toggle-1"
                                    />
                                </Col>
                            </Row>
                            <BinNameLocationRow bin={this.state.bin}/>
                        </FormGroup>
                    </CardBody>
                </Card>
            </FormGroup>
            <FormGroup>
                <Label for="exampleEmail" className="large">2) Select Location</Label>
                <LocationSearch
                        ref={this.locationSearchRef}
                       id="locationAssignmentLocationInput"
                       onEnter={ e => this.onLocationEnter()}
                       onLocationChange={location => this.onLocationChange(location)}/>
                <Label className="medium"><b>Selected Location</b>: {(this.state.location && this.state.location.name) || "None"}</Label>
            </FormGroup>
            <FormGroup>
                <Button color="secondary" size="lg">Reset</Button>
            </FormGroup>
        </div>
    }
}

export default ProductBinLocation;
