import React from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle} from 'reactstrap';
import AppContext from "../../context/AppContext";
import {config as ssoConfig} from "../../custom_vendor/sso";
import Avatar from 'react-avatar';

class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return ( <AppContext.Consumer>
                {({user}) => (
                    <Navbar color="light" light expand="md">
                        <NavbarBrand href="/">Warehouse</NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="mr-auto" navbar>
                            </Nav>
                            <Nav className="ml-auto" navbar>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        IM Links
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem href={"https://purchasing.kjdelectronics.com"}>
                                            Purchasing
                                        </DropdownItem>
                                        <DropdownItem href={"https://packing.kjdelectronics.com"}>
                                            Packing
                                        </DropdownItem>
                                        <DropdownItem href={"https://returns.kjdelectronics.com"}>
                                            Returns
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        {user.displayName} <Avatar src={user.photo} size="25" round={true}/>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem href={`${ssoConfig.baseUrl}logout`}>
                                            Logout
                                        </DropdownItem>
                                        <DropdownItem divider />
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        </Collapse>
                    </Navbar>
                )}
                </AppContext.Consumer>
            );
    }
}

NavBar.propTypes = {
};

export default NavBar