import { Row, Col, Label } from 'reactstrap';
import React from "react";
import PropTypes from 'prop-types';
import {getBinLocationName, getBinProductsList} from "../presentation/BinPresenter";


const BinNameLocationRow = props => {
    return (
        <Row className>
            <Col md={3}>
                <Label className="medium"><b>Selected Bin</b>: {(props.bin && props.bin.name) || "None"}</Label>
            </Col>
            <Col md={3}>
                <Label className="medium"><b>Location</b>: {getBinLocationName(props)}</Label>
            </Col>
            <Col>
                <Label className="medium"><b>Products In Bin</b>: {getBinProductsList(props)}</Label>
            </Col>
        </Row>
    )
};

BinNameLocationRow.propTypes = {
    bin: PropTypes.object.isRequired
};

export default BinNameLocationRow;
