import React, {Component} from 'react';
import './App.css';
import "./lib/bootstrap4-toggle/bootstrap4-toggle.css";

//Setup Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTruckLoading, faBoxOpen} from '@fortawesome/free-solid-svg-icons'
import WarehousePage from "./pages/WarehousePage";
import NavBar from "./components/organisms/NavBar";
import AppProvider from "./context/AppProvider";
import {SsoClient, config as ssoConfig} from "./custom_vendor/sso";
import Cookies from 'universal-cookie';
import {LoadingSpinner} from "./atoms/LoadingSpinner";
library.add(faTruckLoading);
library.add(faBoxOpen);

const cookies = new Cookies();

class App extends Component {
    constructor(props){
        super(props);

        this.state = {
            user: null
        }
    }

    componentDidMount(){
        const sessionKey = cookies.get('kjd_session');
        const ssoClient = new SsoClient ({sessionKey: sessionKey});

        ssoClient.getUser()
            .then(user => {
                this.setState({user: user});
            })
            .catch(err => {
                if(err.statusCode === 401) {
                    window.location.href = ssoConfig.baseUrl;
                }
            })
    }

    render() {
        const sessionKey = cookies.get('kjd_session');

        if(!sessionKey)
            window.location.href = ssoConfig.baseUrl;

        if(!this.state.user)
            return <LoadingSpinner/>;

        return (
            <AppProvider user={this.state.user}>
                <NavBar/>
                <WarehousePage/>
            </AppProvider>
        );
    }
}

export default App;
