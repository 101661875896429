import { Location } from './Location';

class LocationGroup{
    constructor({_id, id, name, code, locationCodes, locations=null}){
        if(_id) this.id = _id.toString();
        else this.id = id;

        this.name = name;
        this.code = code;
        this.locationCodes = locationCodes;

        if(locations && !Array.isArray(locations))
            throw new Error("locations must be an array if provided");
        if(locations){
            let constructed = [];
            locations.forEach(b => constructed.push(new Location(b)));
            this.locations = constructed;
        }
    }

    setLocations(locations){
        this.locations = locations;
    }

    getForDb(){
        let {["id"]:omit, ...res} = this;
        delete res.locations;
        return res;
    }
}

export { LocationGroup };