import React, {Component} from 'react';
import Location from '../components/Location'

class LocationDisplay extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <h2>Location Group</h2>
            {
                this.props.group.locations.map(location =>  <Location location={location}/>)
            }
        </div>
    }
}

export default LocationDisplay;
