import React, {Component} from 'react';
import { FormGroup, Label, Input, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import warehouseClient from './WarehouseClientSingleton'

class GenericSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
        };
        this.currentSearchTimestamp = null;
    }

    handleChange(e) {
        this.setState({
            searchText: e.target.value,
        });
        this.currentSearchTimestamp = new Date().getTime();
        const scopeSearch = this.currentSearchTimestamp;

        let t = this;
        warehouseClient.search(e.target.value)
            .then(result => {
                if(scopeSearch === t.currentSearchTimestamp)
                    t.props.onResultChange(result);
            })
            .catch(err => {
                if(err.statusCode !== 404){
                    return; //TODO Error message
                }
                if(scopeSearch === t.currentSearchTimestamp)
                    t.props.onResultChange(null);
            })
    }

    clearText(){
        this.setState({
            searchText: ''
        })
    }

    handleKeyPress(e){
        if(this.props.onKeyPress) {
            this.props.onKeyPress(e);
        }
    }

    render() {
        return <Input placeholder="K123, UAS1, UA, B-XL-1, etc" bsSize="lg" className={`${this.props.className}`}
                      id={this.props.id}
                      value={this.state.searchText}
                      onChange={e => this.handleChange(e)}
                      onKeyPress={e => this.handleKeyPress(e)}
        />
    }
}

export default GenericSearch;
