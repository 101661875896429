import React, {Component} from 'react';
import { Badge, Row, Col, Card, Button,CardHeader, CardFooter, CardBody, CardTitle, CardText, ListGroup, ListGroupItem } from 'reactstrap';
import ProductListGroupItem from "../components/ProductListGroupItem";
import BinListGroupProductRow from "../components/BinListGroupProductRow";
import warehouseClient from '../components/WarehouseClientSingleton'

class BinDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {locationName: ""};

        this.asyncGetLocationName();
    }

    asyncGetLocationName(){
        warehouseClient.searchForLocation(this.props.bin.locationCode)
            .then(location => {
                this.setState({locationName: location.name});
            })
    }

    render() {
        console.log(this.props.bin.products);

        return <div className="left-margin">
            <h2>Bin</h2>
            <div className="x3Left-margin">
                <Row className="align-items-center">
                    <h3 className="">{this.props.bin.name}</h3>
                    <h3 className="left-margin"><Badge color="info" className="float-right m-2">{this.props.bin.code}</Badge></h3>
                    <h4><Button color="primary" className="left-margin" size="sm" outline={true}
                                href={`https://label.kjdelectronics.com/custom/label-printer-callout?callout=${this.props.bin.code}&barcode=${this.props.bin.code}&line1=${this.props.bin.name}`} target="_blank">
                        Bin Label</Button></h4>
                </Row>
                <Row>
                    <Col md="6">
                        <Card>
                            <CardHeader>Current Location of Bin</CardHeader>
                            <CardBody>
                                <h2><Badge color="dark">{this.props.bin.locationCode}</Badge></h2>
                                <h2>{this.state.locationName}</h2>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <CardHeader>Products in Bin</CardHeader>
                            <CardBody>
                                <ListGroup>
                                    {
                                        this.props.bin.products.sort((a,b) => a.productId - b.productId)
                                            .map(product =>  <ProductListGroupItem product={product} code={this.props.bin.code}/>)
                                    }
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    }
}

export default BinDisplay;
