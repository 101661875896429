import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import {BulkAssignment, ClearBinOrLocation, Managemnet, ProductBinLocation, ReadOnly} from '../tabs'

class WarehousePage extends Component{
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1'
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (
            <div className="App-Width">
                <h1>Warehouse Management</h1>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === '1'})}
                            onClick={() => {
                                this.toggle('1');
                            }}
                        >
                            Read Only
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === '2'})}
                            onClick={() => {
                                this.toggle('2');
                            }}
                        >
                            Product & Bin Location Assignment
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === '3'})}
                            onClick={() => {
                                this.toggle('3');
                            }}
                        >
                            Batch Mode
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === '4'})}
                            onClick={() => {
                                this.toggle('4');
                            }}
                        >
                            Clear Location or Bin
                        </NavLink>
                    </NavItem>
                    <NavItem className="d-none d-md-block">
                        <NavLink
                            className={classnames({active: this.state.activeTab === '5'})}
                            onClick={() => {
                                this.toggle('5');
                            }}
                        >
                            Management and Reports
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <ReadOnly/>
                    </TabPane>
                    <TabPane tabId="2">
                        <ProductBinLocation/>
                    </TabPane>
                    <TabPane tabId="3">
                        <BulkAssignment/>
                    </TabPane>
                    <TabPane tabId="4">
                        <ClearBinOrLocation/>
                    </TabPane>
                    <TabPane tabId="5">
                        <Managemnet/>
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

export default WarehousePage;