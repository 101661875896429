import { Audit } from './audit/Audit';
import { Bin } from './Bin';
import { Product } from './Product';

class Location{
    constructor({_id, id, name, code, audit, bins=null, products=null}){
        if(_id) this.id = _id.toString();
        else this.id = id;

        this.name = name;
        this.code = code;

        if(!name || name === "") throw new Error("name is required for Location");
        if(!code || code === "") throw new Error("code is required for Location");

        if(audit == null)
            audit = new Audit({updated: {userId: '5a00e3c1db1ba1df2d24e83c'}}); //Default to KJD Admin
        this.audit = new Audit(audit);

        if(bins && !Array.isArray(bins))
            throw new Error("bins must be an array if provided");
        if(bins){
            let constructedBins = [];
            bins.forEach(b => constructedBins.push(new Bin(b)));
            this.bins = constructedBins;
        }

        if(products && !Array.isArray(products))
            throw new Error("products must be an array if provided");
        if(products){
            let constructedProducts = [];
            products.forEach(p => constructedProducts.push(new Product(p)));
            this.products = constructedProducts;
        }
    }

    setProducts(products){
        this.products = products;
    }

    setBins(bins){
        this.bins = bins;
    }

    getForDb(){
        let {["id"]:omit, ...res} = this;
        delete res.products;
        delete res.bins;
        return res;
    }
}

export { Location };