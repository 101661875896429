import { ProductLocation } from './ProductLocation';

class Product{
    constructor({_id, id, productId, name, image, locations=[]}){
        if(_id) this.id = _id.toString();
        else this.id = id;

        if(productId == null || typeof productId !== "number" || productId < 0)
            throw new Error('Invalid product id');
        this.productId = productId;
        this.name = name;
        this.image = image;
        if(!Array.isArray(locations))
            throw new Error(`locations must be an object`);

        let builtLocations = [];
        for (let i = 0; i < locations.length; i++) {
            builtLocations.push(new ProductLocation(locations[i]))
        }
        this.locations = builtLocations;
    }

    updateDetailFromImProduct(imProduct){
        this.name = imProduct.detail.name;
        this.image = imProduct.detail.images.primaryImageUrl;
        return this;
    }

    static newFromImProduct(imProduct){
        return new Product({productId: imProduct.key.id, name: imProduct.detail.name, image: imProduct.detail.images.primaryImageUrl});
    }

    hasLocationType(type){
        type = type.toUpperCase();
        let location = this.locations.find(item => item.locationType === type);
        if(location == null) return false;
        return !(location.binCode == null && location.locationCode == null);
    }

    getLocationForType(type){
        type = type.toUpperCase();
        return this.locations.find(item => item.locationType === type);
    }

    setUpdateLocationItem({locationItem}){
        if(!(locationItem instanceof ProductLocation))
            throw new Error("locationItem must be instance of ProductLocation");
        let index = this.locations.findIndex(item => item.locationType === locationItem.locationType);
        if(index === -1) //Does not exist. Push
            this.locations.push(locationItem);
        else
            this.locations[index] = locationItem;
    }

    getLocationItemsAtLocationCode(locationCode){
        let res = this.locations.filter(location => location.locationCode === locationCode);
        if(res) return res;
        return [];
    }

    get mostRecentInventoryManualCount(){
        return this._getMostRecentManualCountForCounter('inventory');
    }

    get mostRecentReserveManualCount(){
        return this._getMostRecentManualCountForCounter('storeReserve');
    }

    _getMostRecentManualCountForCounter(counterName){
        return this.manualCounts.filter(c => c.manualCounts.hasOwnProperty(counterName)).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).shift();
    }

    getForDb(){
        let {["id"]:omit, ...res} = this;
        for (let i = 0; i < res.locations.length; i++) {
            res.locations[i] = res.locations[i].getForDb();
        }
        return res;
    }
}

export { Product };