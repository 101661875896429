import React, {Component} from 'react';
import { Badge, Row, Col, Card, Button,CardHeader, CardFooter, CardBody, CardTitle, CardText, ListGroup, ListGroupItem, Table  } from 'reactstrap';
import Location from '../components/Location'

class LocationDisplay extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <h2>Location</h2>
            <Location location={this.props.location}/>
        </div>
    }
}

export default LocationDisplay;
