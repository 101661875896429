import routes from './config/routes';
import { Product } from '../obj/Product';
import { Bin } from '../obj/Bin';
import { Location } from '../obj/Location';
import { LocationGroup } from '../obj/LocationGroup';
import rp from 'request-promise';
import { config } from './config/config';

class WarehouseClient{
    constructor({apiKey, sessionKey}){
        this.apikey = apiKey;
        this.sessionKey = sessionKey;
    }

    getProductsDict(){
        let options = {
            uri: config.baseUrl + routes.products.get.all,
            method: 'GET',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            json: true
        };
        this._addAuthHeader(options);

        return rp(options)
            .then(products => {
                let dict = {};
                products.forEach(p => {
                    let product = new Product(p);
                    dict[product.productId] = product;
                });
                return dict;
            })
    }

    search(query){
        let options = {
            uri: config.baseUrl + routes.search.get.search.replace("{query}", query),
            method: 'GET',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            json: true
        };
        this._addAuthHeader(options);

        return rp(options)
            .then(result => {
                let constructor;
                if(result.type === "product")
                    constructor = Product;
                else if(result.type === "bin")
                    constructor = Bin;
                else if(result.type === "location")
                    constructor = Location;
                else if(result.type === "group")
                    constructor = LocationGroup;
                result.data = new constructor(result.data);
                return result;
            })
    }

    searchForProduct(query){
        let options = {
            uri: config.baseUrl + routes.products.get.search.replace("{query}", query),
            method: 'GET',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            json: true
        };
        this._addAuthHeader(options);

        return rp(options)
            .then(product => {
                return new Product(product);
            })
    }

    searchForBin(binCode){
        let options = {
            uri: config.baseUrl + routes.bins.get.byCode.replace("{code}", binCode),
            method: 'GET',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            json: true
        };
        this._addAuthHeader(options);

        return rp(options)
            .then(bin => {
                return new Bin(bin);
            })
    }

    searchForLocation(locationCode){
        let options = {
            uri: config.baseUrl + routes.locations.get.byCode.replace("{code}", locationCode),
            method: 'GET',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            json: true
        };
        this._addAuthHeader(options);

        return rp(options)
            .then(location => {
                return new Location(location);
            })
    }

    searchForLocationGroup(groupCode){
        let options = {
            uri: config.baseUrl + routes.locations.groups.get.byCode.replace("{code}", groupCode),
            method: 'GET',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            json: true
        };
        this._addAuthHeader(options);

        return rp(options)
            .then(group => {
                return new LocationGroup(group);
            })
    }

    assignment({productId, binCode, locationCode, locationType, clearBinOfAllProducts=false}){
        let body = {};
        if(productId){
            if(!locationType)
                return Promise.reject({code: 400, message: "Location Type required when productId is passed"});
            body.productId = parseInt(productId);
            if(!body.productId || isNaN(body.productId))
                return Promise.reject({code: 400, message: "product id must be valid int"});
            body.locationType = locationType;
        }
        if(binCode) body.binCode = binCode;
        if(binCode && productId) body.clearBinOfAllProducts = clearBinOfAllProducts; //Only valid when bin and productId provided
        if(locationCode) body.locationCode = locationCode;


        let options = {
            uri: config.baseUrl + routes.assignment.post.assignment,
            method: 'POST',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            body:body,
            json: true
        };
        this._addAuthHeader(options);

        return rp(options);
    }

    bulkAssignment({productIds, locationType="PRIMARY", locationCode, binCode}){
        let body = {
            productIds: productIds,
            locationType: locationType
        };
        if(locationCode) body.locationCode = locationCode;
        if(binCode) body.binCode = binCode;

        let options = {
            uri: config.baseUrl + routes.products.patch,
            method: 'PATCH',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            body: body,
            json: true
        };
        this._addAuthHeader(options);
        return rp(options);
    }

    createBatch({actions, locationType}){
        let body = {
            batch: actions,
            locationType: locationType
        };

        let options = {
            uri: config.baseUrl + routes.batches.post,
            method: 'POST',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            body: body,
            json: true
        };
        this._addAuthHeader(options);
        return rp(options);
    }

    clearLocationGroup(groupCode){
        let body = {};

        let options = {
            uri: config.baseUrl + routes.locations.groups.delete.clear.replace("{code}", groupCode),
            method: 'DELETE',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            body: body,
            json: true
        };
        this._addAuthHeader(options);
        return rp(options);
    }

    clearLocation(locationCode){
        let body = {};

        let options = {
            uri: config.baseUrl + routes.locations.delete.clear.replace("{code}", locationCode),
            method: 'DELETE',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            body: body,
            json: true
        };
        this._addAuthHeader(options);
        return rp(options);
    }

    clearBin(binCode){
        let body = {};

        let options = {
            uri: config.baseUrl + routes.bins.delete.clear.replace("{code}", binCode),
            method: 'DELETE',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            body: body,
            json: true
        };
        this._addAuthHeader(options);
        return rp(options);
    }

    createBin({binCode, binName}){
        let body = {
            binCode,
            binName
        };

        let options = {
            uri: config.baseUrl + routes.bins.get.replace("{code}", ""),
            method: 'POST',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            body: body,
            json: true
        };
        this._addAuthHeader(options);
        return rp(options);
    }

    createLocation({locationCode, locationName}){
        let body = {
            locationCode,
            locationName
        };

        let options = {
            uri: config.baseUrl + routes.locations.get.replace("{code}", ""),
            method: 'POST',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            body: body,
            json: true
        };
        this._addAuthHeader(options);
        return rp(options);
    }

    sendEventForProcessing(event){
        let options = {
            uri: config.baseUrl + routes.events.post,
            method: 'POST',
            headers: {
                'User-Agent': 'Request-Promise'
            },
            body: event,
            json: true
        };
        this._addAuthHeader(options);
        return rp(options);
    }

    _addAuthHeader(options){
        if(this.apikey) options.headers["x-api-key"] = this.apikey;
        if(this.sessionKey) options.headers["kjd_session"] = this.sessionKey;

    }
}

export { WarehouseClient };