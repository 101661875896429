import React, {Component} from 'react';
import { Badge, Row, Col, Card, Button,CardHeader, CardFooter, CardBody, CardTitle, CardText, ListGroup, ListGroupItem, Table  } from 'reactstrap';
import ProductListGroupItem from '../components/ProductListGroupItem'
import BinListGroupItem from "./BinListGroupItem";

class ItemsAtLocation extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Card>
            <CardHeader>Items at Location</CardHeader>
            <CardBody>
                <ListGroup>
                    {
                        this.props.location.products.map(product =>  <ProductListGroupItem product={product} code={this.props.location.code}/>)
                    }
                    {
                        this.props.location.bins.map(bin =>  <BinListGroupItem bin={bin}/>)
                    }
                </ListGroup>
            </CardBody>
        </Card>
    }
}

export default ItemsAtLocation;
