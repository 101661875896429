import React, {Component} from 'react';
import {Badge, Row, Col, Card, CardHeader, CardBody, ListGroup, Table, ListGroupItem, Button} from 'reactstrap';
import ProductListGroupItem from "./ProductListGroupItem";
import ItemsAtLocation from "./ItemsAtLocation";


class Location extends Component {
    constructor(props) {
        super(props);

        this.location = props.location;
    }

    render() {
        return <div className="left-margin">
            <div className="x3Left-margin">
                <Row className="align-items-center">
                    <h3 className="">{this.props.location.name}</h3>
                    <h3 className="left-margin"><Badge color="dark" className="float-right m-2">{this.props.location.code}</Badge></h3>
                    <h4><Button color="primary" className="left-margin" size="sm" outline={true}
                                href={`https://label.kjdelectronics.com/custom/label-printer-callout?callout=${this.props.location.code}&barcode=${this.props.location.code}&line1=${this.props.location.name}`} target="_blank">
                        Location Label</Button></h4>
                </Row>
                <Row>
                    <Col>
                      <ItemsAtLocation location={this.props.location}/>
                    </Col>
                </Row>
            </div>
        </div>

    }
}

export default Location;
