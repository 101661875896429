import React, {Component} from 'react';
import { Col, Button, Form, FormGroup, Label, Input, FormText, Row, ListGroup, ListGroupItem, Alert } from 'reactstrap';
import LocationSearch from "../components/LocationSearch";
import LocationGroupSearch from "../components/LocationGroupSearch";
import BinSearch from "../components/BinSearch";
import warehouseClient from '../components/WarehouseClientSingleton';

class ClearBinOrLocation extends Component {
    constructor(props) {
        super(props);

        this.locationGroupSearchRef = React.createRef();
        this.locationSearchRef = React.createRef();
        this.binSearchRef = React.createRef();

        this.state = {
            location: null,
            bin: null,
            group: null
        }
    }

    handleLocationGroupChange(group){
        this.setState({group: group});
    }

    handleLocationChange(location){
        this.setState({location: location});
    }

    handleBinChange(bin){
        this.setState({bin: bin});
    }

    clearLocationGroup(){
        warehouseClient.clearLocationGroup(this.state.group.code)
            .then(res => {
                this.locationGroupSearchRef.current.clearText();
                this.setState({group: null})
            })
            .catch(err => {
                alert("Clear Location Group Error");
            })
    }

    clearLocation(){
        warehouseClient.clearLocation(this.state.location.code)
            .then(res => {
                this.locationSearchRef.current.clearText();
                this.setState({location: null})
            })
            .catch(err => {
                alert("Clear Location Error");
            })
    }

    clearBin(){
        warehouseClient.clearBin(this.state.bin.code)
            .then(res => {
                this.binSearchRef.current.clearText();
                this.setState({bin: null})
            })
            .catch(err => {
                alert("Clear Location Error");
            })
    }

    renderClearLocationGroup(){
        return <div className="left-margin">
            <Row>
                <Col sm="1">
                    <Label className="large"> Group</Label>
                </Col>
                <Col>
                    <LocationGroupSearch ref={this.locationGroupSearchRef}
                                         onLocationGroupChange={location => this.handleLocationGroupChange(location)} />
                </Col>
            </Row>
            <Row><Col>
                <Label className="medium"><b>Selected</b>: {this.state.group ? this.state.group.name : "None"}</Label>
            </Col></Row>
            <Row><Col>
                <Button color="danger" size="lg" className="left-margin" onClick={e => this.clearLocationGroup()}>Clear Location Group</Button>
            </Col></Row>
        </div>
    }

    render() {
        return <Form>
            <h2>Clear Location Group, Location, or Bin</h2>
            <FormGroup>
                <Label className="large">Clear Location Group</Label>
                {this.renderClearLocationGroup()}
            </FormGroup>
            <FormGroup>
                <Label className="large">Clear Location</Label>
                <div className="left-margin">
                    <Row>
                        <Col sm="1">
                            <Label className="large"> Location</Label>
                        </Col>
                        <Col>
                            <LocationSearch ref={this.locationSearchRef}
                                onLocationChange={location => this.handleLocationChange(location)} />
                        </Col>
                    </Row>
                    <Row><Col>
                        <Label className="medium"><b>Selected</b>: {this.state.location ? this.state.location.name : "None"}</Label>
                    </Col></Row>
                    <Row><Col>
                        <Button color="danger" size="lg" className="left-margin" onClick={e => this.clearLocation()}>Clear Location</Button>
                    </Col></Row>
                </div>
            </FormGroup>
            <FormGroup>
                <Label className="large">Clear Bin</Label>
                <div className="left-margin">
                    <Row>
                        <Col sm="1">
                            <Label className="large"> Bin</Label>
                        </Col>
                        <Col>
                            <BinSearch ref={this.binSearchRef}
                                onBinChange={bin => this.handleBinChange(bin)} />
                        </Col>
                    </Row>
                    <Row><Col>
                        <Label className="medium"><b>Selected</b>: {this.state.bin ? this.state.bin.name : "None" }</Label>
                    </Col></Row>
                    <Row><Col>
                        <Button color="danger" size="lg" className="left-margin" onClick={e => this.clearBin()}>Clear Bin</Button>
                    </Col></Row>
                </div>
            </FormGroup>
        </Form>
    }
}

export default ClearBinOrLocation;
