import React, {Component} from 'react';
import { Badge, Row, Col, Card, Button,CardHeader, Label, CardBody, Form, FormGroup, Input, ListGroupItem, Table  } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import warehouseClient from '../components/WarehouseClientSingleton'

class Managemnet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            binName: "",
            binCode: "",
            locationName: "",
            locationCode: "",
            saving: false
        }
    }

    createBin(){
        this.setState({saving: true})
        warehouseClient.createBin({binName: this.state.binName, binCode: this.state.binCode})
            .then(result => {
                this.setState({binName: "", binCode: "", saving: false})
            })
            .catch(result => {
                this.setState({saving: false})
            })
    }

    createLocation(){
        this.setState({saving: true})
        warehouseClient.createLocation({locationName: this.state.locationName, locationCode: this.state.locationCode})
            .then(result => {
                this.setState({locationName: "", locationCode: "", saving: false})
            })
            .catch(result => {
                this.setState({saving: false})
            })
    }

    render() {
        return <div>
            <h2>Management and Reports</h2>
            <Card>
                <CardHeader>Reports</CardHeader>
                <CardBody>
                    <CardBody style={{"margin": "20px"}}>
                        <Button color="primary" className="left-margin"
                                href="https://products.kjdelectronics.com/v1/reports/inventory/inventory.pdf" target="_blank">
                            Inventory Report</Button><br/>
                        <Button color="primary" className="left-margin top-margin"
                                href="https://products.kjdelectronics.com/v1/reports/inventory/in-stock.pdf" target="_blank">
                            In Stock Only</Button><br/>
                        <Button color="primary" className="left-margin top-margin"
                                href="https://products.kjdelectronics.com/v1/reports/inventory/in-stock-no-location.pdf" target="_blank">
                            No Location Report</Button><br/>
                    </CardBody>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>Manage Bins and Locations</CardHeader>
                <CardBody>
                    <CardBody style={{"margin": "20px"}}>
                        <Form>
                            <FormGroup>
                                <Label for="binName">Bin Name</Label>
                                <Input type="text" name="binName" id="binName" placeholder="Extra Large Bin 1" value={this.state.binName} onChange={(e)  => this.setState({binName: e.target.value})} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="binCode">Bin Code</Label>
                                <Input type="text" name="binCode" id="binCode" placeholder="B-XL-1" value={this.state.binCode} onChange={(e)  => this.setState({binCode: e.target.value})}/>
                            </FormGroup>
                            <Button color="success" size="lg" disabled={this.state.saving} onClick={() => this.createBin()} className="left-margin">Create Bin</Button><br/>
                        </Form>
                        <Form>
                            <FormGroup>
                                <Label for="locationName">Location Name</Label>
                                <Input type="text" name="locationName" id="locationName" placeholder="Extra Large Bin 1" value={this.state.locationName}  onChange={(e)  => this.setState({locationName: e.target.value})}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="locationCode">Location Code</Label>
                                <Input type="text" name="locationCode" id="binCode" placeholder="B-XL-1" value={this.state.locationCode} onChange={(e)  => this.setState({locationCode: e.target.value})} />
                            </FormGroup>
                            <Button color="success" size="lg" disabled={this.state.saving} onClick={() => this.createLocation()} className="left-margin top-margin">Create Location</Button>
                        </Form>
                    </CardBody>
                </CardBody>
            </Card>
        </div>
    }
}

export default Managemnet;
