import React, {Component} from 'react';
import { FormGroup, Label, Input, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import warehouseClient from './WarehouseClientSingleton'

class ProductSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
        };
        this.currentSearchTimestamp = null;
        this.searchPending = false;
    }

    handleChange(e) {
        this.setState({
            searchText: e.target.value,
        });
        this.currentSearchTimestamp = new Date().getTime();
        const scopeSearch = this.currentSearchTimestamp;

        if(e.target.value === ""){
            this.props.onProductChange(null);
            return;
        }
        this.searchPending = true;
        this.searchFinishCallback = null; //Clear ending pending callback
        let t = this;

        warehouseClient.searchForProduct(e.target.value)
            .then(product => {
                if(scopeSearch === t.currentSearchTimestamp)
                    t.props.onProductChange(product);
                this.finishSearch();
            })
            .catch(err => {
               if(err.statusCode !== 404){
                    return; //TODO Error message
                }
                if(scopeSearch === t.currentSearchTimestamp)
                    t.props.onProductChange(null);
                this.finishSearch();
            })
    }

    finishSearch(){
        this.searchPending = false;
        if(this.searchFinishCallback) {
            this.searchFinishCallback();
            this.searchFinishCallback = null;
        }
    }

    clearText(){
        this.setState({searchText: ''});
        this.props.onProductChange(null);
    }

    getText(){
        return this.state.searchText;
    }

    handleKeyPress(e){
        if(this.props.onKeyPress) {
            this.props.onKeyPress(e);
        }
        if (e.key === "Enter")
            this.onEnter(e);
    }

    onEnter(e){
        if(!this.props.onEnter) return;

        if(this.searchPending) {
            this.searchFinishCallback = () => {
                setTimeout(() => this.props.onEnter(e), 100);
            };
        }
        else
            setTimeout(() => this.props.onEnter(e), 100);
    }

    render() {
        return <Input placeholder="K123, MPN, FNSKU, etc" bsSize="lg" className={`${this.props.className}`}
                      id={this.props.id}
                      value={this.state.searchText}
                      onChange={e => this.handleChange(e)}
                      onKeyPress={e => this.handleKeyPress(e)}
        />
    }
}

export default ProductSearch;
