import React, {Component} from 'react';
import { Label,} from 'reactstrap';

class Toggle extends Component {
    constructor(props) {
        super(props);
        //TODO Add ability to change Size can be sm, normal, large

        this.state = {
            checked: !!this.props.default || false
        }
    }

    onToggle(){
        let toggled = !this.state.checked;
        console.log(toggled);
        this.setState({checked: toggled});
        if(this.props.onToggle)
            this.props.onToggle(toggled);
    }

    render() {
        return <div className="form-group pt-2 mb-0">
              <span className={"switch switch-normal align-middle"}>
                <input tabIndex={this.props.tabindex ? this.props.tabindex : null} type="checkbox" className="switch" id={this.props.id} onChange={() => this.onToggle()} checked={this.state.checked}/>
                <Label htmlFor={this.props.id}>{this.props.label}</Label>
              </span>
            </div>
    }
}

export {Toggle}