import React, {Component} from 'react';
import { FormGroup, Label, Input, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import warehouseClient from './WarehouseClientSingleton'

class BinSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: ''
        };
        this.currentSearchTimestamp = null
    }

    handleChange(e) {
        this.setState({
            searchText: e.target.value
        });
        if(this.props.onTextChange)
            this.props.onTextChange(e.target.value);

        this.currentSearchTimestamp = new Date().getTime();
        const scopeSearch = this.currentSearchTimestamp;

        let t = this;
        if(e.target.value === ""){
            t.props.onBinChange(null);
            return;
        }

        warehouseClient.searchForBin(e.target.value)
            .then(bin => {
                if(scopeSearch === t.currentSearchTimestamp)
                    t.props.onBinChange(bin);
            })
            .catch(err => {
                if(err.statusCode !== 404){
                    return; //TODO Error message
                }
                if(scopeSearch === t.currentSearchTimestamp)
                    t.props.onBinChange(null);
            });
    }

    handleKeyPress(e) {
        if (this.props.onKeyPress)
            this.props.onKeyPress(e);
        if (e.key === "Enter")
            this.onEnter();
    }


    onEnter(){
        if(this.props.onEnter)
            this.props.onEnter();
    }

    clearText(){
        this.setState({searchText: ''});
        this.props.onBinChange(null);
    }

    getText(){
        return this.state.searchText;
    }

    render() {
        return <Input placeholder={!this.props.disabled ? "B-XS-1" : ""} bsSize="lg" className={`${this.props.className}`}
                      id={this.props.id}
                      value={this.state.searchText}
                      onChange={e => this.handleChange(e)}
                      disabled={this.props.disabled}
                      onKeyPress={e => this.handleKeyPress(e)}
        />
    }
}

export default BinSearch;
