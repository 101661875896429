function getBinLocationName({bin}) {
    if(!bin) return "None";
    if(!bin.locationCode) return "None";
    return bin.locationCode;
}

function getBinProductsList({bin}) {
    if(!bin) return "None";
    if(bin.products.length === 0) return "None";
    return bin.products.map(product => `K${product.productId}`).join(',');
}

export  {
    getBinLocationName, getBinProductsList
}