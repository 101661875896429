import React, {Component} from 'react';
import { Badge, ListGroupItem  } from 'reactstrap';


class ProductListGroupItem extends Component {
    constructor(props) {
        super(props);

        this.product = props.product;
    }

    getBadgeByLocationType(locationType){
        let location = this.props.product.getLocationForType(locationType);
        if(!location) return "";
        if(location.getCode() === this.props.code)
            return <Badge color="secondary" className="horizontal-margin location-type-badge" pill>{locationType}</Badge>;
        else return "";
    }

    render() {
        return <ListGroupItem>
            <Badge color="primary" className="k-number-badge">K{this.props.product.productId}</Badge>&nbsp;
            {this.getBadgeByLocationType("Primary")}&nbsp;
            {this.getBadgeByLocationType("Reserve")}&nbsp;
            {this.props.product.name}
        </ListGroupItem>

    }
}

export default ProductListGroupItem;
